import axios from "axios";

const URL = "https://api.pocketplay.in";

export const getDashboard = async () => {
  try {
    const response = await axios.get(`${URL}/admin/dashboard`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};
export const getSystemValue = async () => {
  const { data } = await axios.get(`${URL}/system-values`);
  return data;
};

export const updateSystemValues2 = async (data) => {
  const { data: res } = await axios.put(`${URL}/admin/system-values`, data);
  return res;
};
export const getBanners = async () => {
  try {
    const response = await axios.get(`${URL}/admin/banners`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getAllMovies = async () => {
  try {
    const response = await axios.get(`${URL}/movies`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getAllSeries = async () => {
  try {
    const response = await axios.get(`${URL}/series`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getAllCategories = async () => {
  try {
    const response = await axios.get(`${URL}/categories`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const createNewBanner = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/banner`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const updateBanner = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/banner/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const deleteBanner = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/banner/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addCategory = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/category`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const updateCategory = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/category/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/category/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getSubCategories = async (id) => {
  try {
    const response = await axios.get(`${URL}/category/${id}/subcategory`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addSubCategory = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/subcategory`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const updateSubCategory = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/subcategory/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const deleteSubCategory = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/subcategory/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addMovietoSubCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory/add-movie`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addSeriesToSubCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory/add-series`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const removeMovieFromSubCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory/remove-movie`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const removeSeriesFromSubCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory/remove-series`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const changeSeriesPosition = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory-update/series-position`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const changeMoviePosition = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subcategory-update/movie-position`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getAllGenres = async () => {
  try {
    const response = await axios.get(`${URL}/admin/genres`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addGenre = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/genre`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addMovie = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/movie`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const deleteMovie = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/movie/${id}`);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const updateMovie = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/movie/${id}`, data);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addSeries = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/series`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const deleteSeries = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/series/${id}`);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const updateSeries = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/series/${id}`, data);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getSeriesById = async (id) => {
  try {
    const response = await axios.get(`${URL}/series/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addEpisode = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/series/add-episode`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const updateEpisode = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/series/update-episode`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const deleteEpisode = async (data) => {
  try {
    const response = await axios.delete(`${URL}/admin/series/delete-episode`, {
      data: data,
    });
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${URL}/admin/users`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/user`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/user/${id}`);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const updateUser = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/user/${id}`, data);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};
export const getUserNotication = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/user-notifications/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addUserNotification = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/notification`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const loginAdmin = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/login`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const updateUserImage = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/user-image/${id}`, data);

    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addCastToMovie = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/add-movie-cast/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addCastToSeries = async (id, data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/add-series-cast/${id}`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addTopBanner = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/top-banner`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getTopBanner = async () => {
  try {
    const response = await axios.get(`${URL}/topbanners`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const deleteTopBanner = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/top-banner/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const updateTopBanner = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/top-banner/${id}`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addShortBanner = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/short-banner`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getShortBanner = async () => {
  try {
    const response = await axios.get(`${URL}/shortbanner`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const deleteShortBanner = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/short-banner/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const addSubscribtion = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/subscription`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateSubscription = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/subscription/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllSubscribtions = async () => {
  try {
    const response = await axios.get(`${URL}/all-subscriptions`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const userSubHistory = async (id) => {
  try {
    const response = await axios.get(
      `${URL}/admin/user-subscription-history/${id}`
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const updateUserSubscription = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/user-subscription`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const deleteSubscription = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/subscription/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addDataToSubcription = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subscription/add-video`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
